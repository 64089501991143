html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  /* vertical-align: baseline; */
  background: transparent;
  font-family: "微软雅黑";
}

a {
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

/* remember to define focus styles! */

:focus {
  outline: 0;
}

/* remember to highlight inserts somehow! */

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  /* 只有定义了表格的布局算法为fixed，下面td的定义才能起作用。 */
}

td {
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  /* overflow-y: hidden; */
  overflow: auto;
}

body {
  /* background: #000000; */
  min-width: 1200px;
}

input::-webkit-input-placeholder,
textarea ::-webkit-textarea-placeholder {
  color: #5f6a81;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #5f6a81;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #5f6a81;
}

input:-ms-input-placeholder,
textarea:-ms-textarea-placeholder {
  /* Internet Explorer 10-11 */
  color: #5f6a81;
}

button,input {
  outline: none;
  border: none;
}

/* *::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  -moz-border-radius: 7.5px;
  -ms-border-radius: 7.5px;
  -o-border-radius: 7.5px;
} */

/* *::-webkit-scrollbar-thumb {
  background: #7059EF;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
} */
 
input[type="number"] {
    -moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #5f6a81;
  font-size: 14px;
}
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #5f6a81;
  font-size: 14px;
}
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #5f6a81;
  font-size: 14px;
}
textarea::-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #5f6a81;
  font-size: 14px;
}
