.submodule{
    margin-top:70px!important;
}
/* 警告框样式 */
.success-alert{
    z-index: 9999;
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    position: relative;
}
.error-alert{
    z-index: 9999;
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
    position: relative;
}
.warn-alert{
    z-index: 9999;
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    position: relative;
}
/*商品样式 */
.single-goods{
    display: inline-block;
    width: 280px;
    background:#fff;
    box-sizing: border-box;
    padding:10px;
    margin: 0 10px;
    margin-top:20px;
}
.single-goods .goods-photo{
    overflow: hidden;
}
.single-goods .goods-photo>img{
    width: 100%;
    cursor: pointer;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}
.single-goods .goods-photo>img:hover{
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}
.single-goods .goods-name{
    font-size: 16px;
    font-weight: bold;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.single-goods .goods-stat{
    line-height: 30px;
    color:#999;
}
.single-goods .goods-stat .goods-price{
    font-size:12px;
}
.single-goods .goods-stat .goods-price>b{
    display: inline-block;
    color: #ffa39e;
    font-size:15px;
    margin-left:5px;
}
.single-goods .goods-stat .count{
    float: right;
    font-size:12px;
}
.not-data{
    font-size: 30px;
    font-weight: bold;
    line-height: 100px;
    text-align: center;
}
.error-info{
    font-size: 12px;
    line-height: 25px;
    color: red;
}